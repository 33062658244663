import crypto from 'crypto';

export const capitalize = (str: string) => `${str?.charAt?.(0)?.toUpperCase?.()}${str?.slice?.(1)?.toLowerCase?.()}`;

export const lowercaseFirst = (str: string) => `${str?.charAt?.(0)?.toLowerCase?.()}${str?.slice?.(1)}`;

export const uppercaseWords = (str: string): string =>
	str
		?.toLowerCase()
		?.split(/[_\s]/)
		?.map((i: string) => capitalize(i))
		?.join(' ');

export const camelCase = (str: string) => str.trim().replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));

export const slugify = (str: string): string =>
	str
		.toLowerCase()
		.replace(/\s+/g, '-')
		.replace(/[^\w-]+/g, '');

export const isContain = (string: string, searchValue: string) => {
	const lowerStr = string?.toLocaleLowerCase?.()?.trim();
	const lowerSearchValue = searchValue?.toLocaleLowerCase?.()?.trim();
	return lowerStr?.includes(lowerSearchValue);
};

export const isSomeContain = (searchValue: string, values: any[]) => values.some(sp => !!sp && isContain(sp, searchValue));

export const isEmail = (str: string) =>
	/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
		str
	);

export const jwtDecode = (token: string): { exp?: number; iat?: number } => {
	if (token === '') return {};
	return JSON.parse(
		decodeURIComponent(
			atob(token.split('.')[1]?.replace('-', '+')?.replace('_', '/'))
				.split('')
				.map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
				.join('')
		)
	);
};

export const generateRandomString = (length: number) => {
	const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	const numbers = '0123456789';
	return Array(length)
		.fill('')
		.map(_ => {
			const set = Math.random() < 0.5 ? chars : numbers;
			return set[~~(Math.random() * set.length)];
		})
		.join('');
};

export const uid = () => generateRandomString(~~Math.random() * 10 + 3);

export const createHashId = (str: string) => crypto.createHash('sha256').update(str).digest('base64');

export const urlHashes = (hash?: string) => hash?.split('#')?.slice(1) || [];

export const emailRegex =
	/^((([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,}))?$/i;

export const isUpperCase = (str: string) => /^[A-Z]*$/.test(str);

export const numFormatter = (num: number) => num?.toLocaleString('en', { minimumFractionDigits: 2 });

export const userNameAbbreviation = (str: string) => {
	if (!str) return '';
	const parts = str?.split(' ');
	if (parts.length === 1) return str.slice(0, 2)?.toUpperCase();
	else {
		const first = parts[0].slice(0, 1);
		const last = parts[parts.length - 1].slice(0, 1);
		return `${first}${last}`;
	}
};

export const priceFormatter = (num: number | string) =>
	(+(num || 0))?.toLocaleString('en', {
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
		style: 'currency',
		currency: 'USD',
	});

export const generateRandomBlueHex = () => {
	const red = Math.floor(Math.random() * 56);
	const green = Math.floor(Math.random() * 56);
	const blue = Math.floor(Math.random() * 256);
	return '#' + red.toString(16).padStart(2, '0') + green.toString(16).padStart(2, '0') + blue.toString(16).padStart(2, '0');
};

export const splitString = (str: string) => {
	let parts = str?.split?.(/(\d+)/);
	let letters = parts?.[0];
	let numbers = parts?.[1];
	return `${letters} #${numbers}`;
};

export const NoFormatter = (no: string) => no?.replace(/([A-Za-z]+)0*(\d+)/, '$1 #$2') || '';
